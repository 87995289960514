<script setup lang="ts">
import { useLimits } from '~/store/limits'

const props = defineProps<{
  type: 'page' | 'aside'
}>()

const store = useLimits()
const { data } = storeToRefs(store)
const { update } = store

if (props.type === 'aside')
  update()
</script>

<template>
  <template v-if="type === 'aside'">
    <p v-if="Object.keys(data).length > 0" class="p-0 max-w-40 text-primary-small text-secondary-500 show">
      За последние 30 дней {{ pluralForm(['создан', 'создано', 'создано'], data.month_count) }} {{ data.month_count }} {{ pluralForm(['запрос', 'запроса', 'запросов'], data.month_count) }}
      <!-- {{ data.day_limit === 0 ? '' : `из ${data.day_limit}` }} -->
    </p>
    <div v-else class="h-10 w-40 p-0">
      <RSkeleton bg-class="bg-secondary-300" class="w-full h-10" />
    </div>
  </template>

  <template v-else>
    <p v-if="Object.keys(data).length > 0" class="text-primary-large show">
      <span class="opacity-50">За последние 30 дней {{ pluralForm(['создан', 'создано', 'создано'], data.month_count) }}</span> {{ data.month_count }} <span class="opacity-50">{{ pluralForm(['запрос', 'запроса', 'запросов'], data.month_count) }}</span>
    </p>
    <RSkeleton v-else class="w-full h-[28px]" />
  </template>
</template>

<style scoped>

</style>
